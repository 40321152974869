<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i><span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE GESTIONES DE EVALUACIONES DE RIESGOS</span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-cogs fa-3x"></i> <br>
                            <span class="lg-numero">{{datosEstadistica.gestiones}} / {{datosEstadistica.finalizadas}}</span>
                            <br>
                            <span class="text-muted">Gestiones / Finalizados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Aspectos ambientales</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="4" class="my-2">
                            <b-button block variant="custom" :to="{name: 'Evaluación 14001'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br> Volver
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Gestiones registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaGestionesAspectos" :fields="campoGestionesAspecto" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('004-14001-GES','u') == 1" @click="consultarGestionMedidas(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('004-14001-GES','d') == 1" @click="eliminarGestion(param)" size="sm" variant="danger" class="mr-1 mb-1" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>

        <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalActualizarGestionAspecto">
            <template #header>
                <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                    <span class="h5"> Detalle gestión de riesgo</span></h6>
                <CButtonClose @click="modalActualizarGestionAspecto = false" class="text-white" />
            </template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(modificarGestionAspecto)">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="fecha de gestión" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha de gestión:" class="mb-2">
                                    <b-form-input type="date" v-model="datosActualizarGestionAspecto.fechaGestion" :state="getValidationState(validationContext)"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="documentación" :rules="{}" v-slot="validationContext">
                                <b-form-group label="Documentación:" class="mb-2">
                                    <b-input-group>
                                        <b-input-group-prepend v-if="datosActualizarGestionAspecto.urlDocumentacion != ''">
                                            <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosActualizarGestionAspecto.urlDocumentacion)" v-c-tooltip="'Descargar'">
                                                <i class="fas fa-download fa-xs"></i>
                                            </b-button>
                                        </b-input-group-prepend>
                                        <b-form-file ref="file" v-model="datosActualizarGestionAspecto.documentacion" v-on:change="handleFileUpload" :placeholder="datosActualizarGestionAspecto.nombreDocumentacion ? datosActualizarGestionAspecto.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosActualizarGestionAspecto.nombreDocumentacion ? datosActualizarGestionAspecto.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-input-group>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12" class="my-2">
                            <span>Medidas a implantar:</span>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="datosActualizarGestionAspecto.listaMedidasImplantar.filter(x => x.estado == '2')" :fields="campoMedidasImplantar" class="table-custom" mediun responsive outlined fixed hover>
                                <template v-slot:cell(responsable)="row">
                                    <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" v-model="row.item.idPuestoTrabajo" :options="comboCargoResponsable">
                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                    </v-select>
                                    <v-select :reduce="comboResponsable =>comboResponsable.idEmpleado" class="mt-2" label="nombres" placeholder="Seleccione una opción" v-model="row.item.idEmpleado" :options="comboResponsable">
                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Recursos humanos'}"> Registra aquí</router-link></span>
                                    </v-select>
                                </template>
                                <template v-slot:cell(recomendacion)="row">
                                    <b-col md="12">
                                        <b-form-textarea rows="3" max-rows="6" placeholder="Ingrese recomendación" v-model="row.item.recomendacion"></b-form-textarea>
                                    </b-col>
                                </template>
                                <template v-slot:cell(plazo)="row">
                                    <b-form-select v-model="row.item.plazo" :options="comboPlazo" value-field="idPlazo" text-field="descripcion">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </template>
                                <template v-slot:cell(ejecutada)="row">
                                    <b-form-checkbox plain :id="'checkbox-'+row.index" v-model="row.item.ejecutada" :name="'checkbox-'+row.index" value="2" unchecked-value="1"></b-form-checkbox>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button @click="quitarFila(param)" v-if="datosActualizarGestionAspecto.listaMedidasImplantar.filter(x => x.estado == '2').length >= 2" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                    <!-- <b-button v-if="param.item.idEvaluacionDetalleMedida!=''" @click="eliminarMedida(param)" v-show="datosActualizarGestionAspecto.listaMedidasImplantar.length >= 2" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button> -->
                                </template>
                            </b-table>
                        </b-col>
                        <b-col md="12">
                            <b-row class="text-center">
                                <b-col md="12">
                                    <CButton @click="agregarFila()" class="mr-1" size="sm" color="dark">
                                        <i class="fas fa-plus fa-sm"></i> Añadir medida
                                    </CButton>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col class="my-2 text-right" md="12">
                            <b-button :disabled="disabled" variant="success" class="mr-2" type="submit">
                                Guardar
                            </b-button>
                            <b-button variant="danger" @click="modalActualizarGestionAspecto = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <template #footer>
                <span></span>
            </template>
        </CModal>

    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalActualizarGestionAspecto: false,
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoGestionesAspecto: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "fechaEvaluacion",
                    label: "Fecha de evaluación",
                    class: "text-center",
                },
                {
                    key: "fechaGestion",
                    label: "Fecha de gestión",
                    class: "text-center",
                },
                {
                    key: "aspecto",
                    label: "Aspecto",
                    class: "text-center",
                },
                {
                    key: "medidasEjecutadas",
                    label: "Medidas / Ejecutadas",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: '',
                    class: "text-center"
                }
            ],
            campoMedidasImplantar: [{
                    key: "responsable",
                    label: "Responsable",
                    class: "text-center",
                },
                {
                    key: "recomendacion",
                    label: "Recomendación",
                    class: "text-center",
                },
                {
                    key: "plazo",
                    label: "Plazo",
                    class: "text-center",
                },
                {
                    key: "ejecutada",
                    label: "Ejecutada",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaGestionesAspectos: [],

            datosActualizarGestionAspecto: {
                idEvaluacionDetalleGestion: '',
                idEvaluacionDetalleAspecto: '',
                fechaGestion: '',
                documentacion: null,
                urlDocumentacion: '',
                nombreDocumentacion: '',
                estado: 2,
                listaMedidasImplantar: []
            },
            datosSession: {
                idCliente: ''
            },
            disabled: false,
            datosEstadistica: {
                gestiones: 0,
                finalizadas: 0
            },
            comboCargoResponsable: [],
            comboResponsable: [],
            comboPlazo: [{
                idPlazo: '1',
                descripcion: '6 Meses'
            }, {
                idPlazo: '2',
                descripcion: '12 Meses'
            }, {
                idPlazo: '3',
                descripcion: '18 Meses'
            }, {
                idPlazo: '4',
                descripcion: 'Otros'
            }],

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        agregarFila() {
            this.datosActualizarGestionAspecto.listaMedidasImplantar.push({
                idEvaluacionDetalleMedida: '',
                idPuestoTrabajo: null,
                idEmpleado: null,
                recomendacion: '',
                plazo: null,
                ejecutada: '1',
                estado: 2,
            })
        },
        descargarDocumento(url) {
            window.open(url)
        },
        quitarFila(param) {
            let me = this;
            let listaMedidasImplantar = me.datosActualizarGestionAspecto.listaMedidasImplantar.filter(x => x.estado == 2);
            if (listaMedidasImplantar[param.index].idEvaluacionDetalleMedida) {
                listaMedidasImplantar[param.index].estado = 1;
            } else if (!listaMedidasImplantar[param.index].idEvaluacionDetalleMedida) {
                listaMedidasImplantar[param.index].estado = 0;
                for (let e in me.datosActualizarGestionAspecto.listaMedidasImplantar) {
                    if (me.datosActualizarGestionAspecto.listaMedidasImplantar[e].estado == 0) {
                        me.datosActualizarGestionAspecto.listaMedidasImplantar.splice(e, 1);
                    }
                }
            }
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                this.datosActualizarGestionAspecto.documentacion = this.$refs.file.files[0];
            }
        },
        listarGestionesAspectos() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-gestiones-aspectos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaGestionesAspectos = response.data;
                    me.totalRows = me.listaGestionesAspectos.length;
                    me.datosEstadistica.gestiones = 0;
                    me.datosEstadistica.finalizadas = 0;

                    for (const i in response.data) {
                        me.datosEstadistica.gestiones += parseInt(response.data[i].medidas);
                        me.datosEstadistica.finalizadas += parseInt(response.data[i].ejecutadas);
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarGestionesAspectosEvaluacion() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-gestiones-aspectos-evaluacion", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.listaGestionesAspectos = response.data;
                    me.totalRows = me.listaGestionesAspectos.length;
                    me.datosEstadistica.gestiones = 0;
                    me.datosEstadistica.finalizadas = 0;

                    for (const i in response.data) {
                        me.datosEstadistica.gestiones += parseInt(response.data[i].medidas);
                        me.datosEstadistica.finalizadas += parseInt(response.data[i].ejecutadas);
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });

        },
        consultarGestionMedidas(param) {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-gestion-medidas-sgma", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacionDetalleGestion: param.item.idEvaluacionDetalleGestion
                        },
                    }
                )
                .then(function (response) {
                    me.datosActualizarGestionAspecto.listaMedidasImplantar.length = 0;
                    me.datosActualizarGestionAspecto.idEvaluacionDetalleGestion = response.data[0].idEvaluacionDetalleGestion;
                    me.datosActualizarGestionAspecto.idEvaluacionDetalleAspecto = response.data[0].idEvaluacionDetalleAspecto;
                    me.datosActualizarGestionAspecto.fechaGestion = response.data[0].fechaGestion;
                    me.datosActualizarGestionAspecto.urlDocumentacion = response.data[0].urlDocumentacion;
                    me.datosActualizarGestionAspecto.nombreDocumentacion = response.data[0].nombreDocumentacion;
                    me.datosActualizarGestionAspecto.estado = response.data[0].estadoGestion;
                    for (const i in response.data) {
                        me.datosActualizarGestionAspecto.listaMedidasImplantar.push({
                            idEvaluacionDetalleMedida: response.data[i].idEvaluacionDetalleMedida,
                            idPuestoTrabajo: response.data[i].idCargoResponsable,
                            idEmpleado: response.data[i].idResponsable,
                            recomendacion: response.data[i].recomendacion,
                            plazo: response.data[i].plazo,
                            ejecutada: response.data[i].ejecutada,
                            estado: response.data[i].estado,
                        })
                    }

                    me.modalActualizarGestionAspecto = true;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEmpleados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        modificarGestionAspecto() {
            let me = this;
            me.disabled = true;
            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosActualizarGestionAspecto.documentacion);
            formData.append("folder", 'sgma/evaluacion-aspectos/gestiones-documentos');

            formData.append("datosGestion", JSON.stringify(me.datosActualizarGestionAspecto));
            formData.append("idCliente", me.datosSession.idCliente);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/modificar-gestion-aspecto",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    if (me.$route.params.id != null) {
                        me.listarGestionesAspectosEvaluacion();
                    } else {
                        me.listarGestionesAspectos();
                    }

                    me.modalActualizarGestionAspecto = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetModalActualizarGestion() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
        },
        eliminarGestion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la gestión?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-gestion-sgma", {
                            idEvaluacionDetalleGestion: param.item.idEvaluacionDetalleGestion,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            if (me.$route.params.id != null) {
                                me.listarGestionesAspectosEvaluacion();
                            } else {
                                me.listarGestionesAspectos();
                            }
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        // eliminarMedida(param) {
        //     let me = this
        //     me.$swal.fire({
        //         title: '¿Estas seguro de eliminar la medida?',
        //         text: "¡No podrás revertir esto!",
        //         icon: 'warning',
        //         showCancelButton: false,
        //         confirmButtonColor: '#d33',
        //         confirmButtonText: 'Eliminar',
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             axios
        //                 .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-medida-sgma", {
        //                     idEvaluacionDetalleMedida: param.item.idEvaluacionDetalleMedida,
        //                 }, {
        //                     headers: {
        //                         Authorization: `Bearer ${localStorage.token}`,
        //                     }
        //                 })
        //                 .then(function (response) {
        //                     let color = response.data.resultado == 1 ? "success" : "error";
        //                     me.swat(color, response.data.mensaje)
        //                     me.datosActualizarGestionAspecto.listaMedidasImplantar.splice(param.index, 1);
        //                     if (me.$route.params.id != null) {
        //                         me.listarGestionesAspectosEvaluacion();
        //                     } else {
        //                         me.listarGestionesAspectos();
        //                     }
        //                 })
        //                 .catch(function (error) {
        //                     me.swat('error', 'Algo salió mal!')
        //                 });
        //         }
        //     })
        // },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalActualizarGestionAspecto: function (val) {
            if (val == false) {
                this.resetModalActualizarGestion();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarPuestosTrabajo();
            this.listarEmpleados();
            if (this.$route.params.id != null) {
                this.listarGestionesAspectosEvaluacion();
            } else {
                this.listarGestionesAspectos();
            }

        }
    }

}
</script>
